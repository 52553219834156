
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useRouter } from 'next/router';
import { useEffect } from 'react';
const Real404 = () => {
    const router = useRouter();
    /**
     * doing this because i18n does not work in reserved 404 pages
     * https://github.com/i18next/next-i18next/issues/1235
     */
    useEffect(() => {
        router.replace('/notfound');
    }, []);
};
export default Real404;

    async function __Next_Translate__getStaticProps__194cb667968__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194cb667968__ as getStaticProps }
  